module.exports = {
	"common.footer.142r87": "ホーム",
	"common.footer.2984be": "秘密のデート",
	"common.footer.820xnr": "ライブ・ストリーミング・ルーム",
	"common.footer.bwy7w8": "マイ",
	"choose.list.8dj7a1": "北京",
	"choose.list.7oj6hs": "リフレッシュ成功！",
	"home.detail.6emj72": "X歳／身長 ",
	"home.detail.7p7of1": "プロフィール",
	"home.detail.n653wj": "デートのフィードバック",
	"home.detail.787sgk": "住所の詳細：*****",
	"home.detail.5vs1b3": "連絡先の詳細：*****",
	"home.detail.chdtoq": "基本情報",
	"home.detail.q7k3xx": "所在地",
	"home.detail.gd236k": "カード情報 ******",
	"home.detail.q56bck": "公式デートのお申し込みは、カスタマーサービスまでご連絡ください。",
	"home.detail.on80yp": "デートを申し込む",
	"home.detail.oht807": "カスタマーサービスまでご連絡ください。",
	"home.index.m831o8": "このページでは、公式デートマッチに参加された会員様の情報の一部のみを表示しています。より多くの大人のデート情報を確認したい場合は、公式カスタマーサービススタッフまでお問い合わせください",
	"home.index.nl3o64": "大人の心理的・身体的ニーズに対応したオープンな大人の出会いプラットフォームとして、「出会い」という概念は全く新しい意味を持つようになりました！",
	"home.index.h75t24": "ニックネーム：",
	"home.index.b2d1n2": "プロフィール：",
	"home.index.589121": "エリア：",
	"home.index.72pckr": "認証済み",
	"home.index.7521p4": "コンテンツモデレーションチームは、すべての出会い系広告を掲載前に注意深くモデレーションし、当社の基準を満たさない広告はブロックされ削除されます。",
	"home.index.yzxn3c": "また、アクセス制御だけでなく、手順やその他の措置の定期的なチェックと評価も実施しています。",
	"home.index.102566": "あなたのプライバシーとセキュリティは、私たちにとって非常に重要です。私たちは、プライベートプロファイルオプションを用意しており、第三者と情報を共有することはありません。あなたの情報プロフィールが承認され、私たちの検証チーム（私たちのプラットフォームが偽のプロフィール、詐欺師、セックスワーカー、ジャーナリストなどから自由であることを保証することを目的として）によって検証された後、別の人生を開くために開始することができます！",
	"home.index.ftsut5": "会員になるメリットはたくさんあります",
	"home.index.y75fd5": "関係を維持するために多くの時間と労力を費やす必要はありません。",
	"home.index.h83si8": "人間関係の束縛を受けることなく、心理的・肉体的欲求を満たすことができます。社会的関係の束縛を受ける必要がなく、自分がどのように扱われたいかを正確に決めることができます。",
	"home.index.bupuo6": "同伴者が欲しいとき、出張に行きたいとき、休暇に行きたいとき、カスタマーサービスを誘うだけで、誰かとマッチングさせることができます。",
	"home.index.015xt1": "自分より若く感じたり、生活や仕事のある面で助けてくれる人を選ぶことができ、人生にやりがいや喜びをもたらしてくれるでしょう。",
	"home.index.241j69": "もう合わないと思ったら、相手に伝えて関係を終わらせればいいのです。",
	"home.index.r764wg": "なぜ参加しなければならないのですか",
	"home.index.5g8uu8": "最初のコミットメントなしに、同じ志を持つ大人たちと、より多様なつながりを作るスリルを体験してください。 じっくりと時間をかけて、幅広いソーシャル・サークル情報を閲覧し、準備ができたら、エキサイティングで無限の可能性を秘めた世界の扉を開けてください。 新しい生き方への扉が開かれます！",
	"home.index.p8dvv7": "ここでは、あなたにぴったりの人がきっと見つかります。何万人ものリアルなプロフィールから、お住まいの地域、距離の範囲、年齢層、趣味や習慣など、ホームサーチ機能を使って検索することができます。",
	"home.index.dkx49a": "私たちのプラットフォームには自信があるので、無料で私たちのソーシャルサークルのいくつかを閲覧し、探索することができます。",
	"home.index.q8toq9": "インターネットアダルト出会いにおける長年の経験により、私たちはアダルト出会いにおいて最も信頼されているブランドです。",
	"home.index.4i6p6e": "当社のデータベースには数百万人のユーザーが登録されており、理想的なパートナーを探している世界中の質の高いユーザーによる多様な会員基盤を持っていることを誇りに思っています。 これまでに何十万人ものユーザーが、私たちのオープンなソーシャルサークルで理想的な相手を見つけ、その多くの興味深いストーリーを皆と共有しています。",
	"home.index.q5v662": "ですから、時間を無駄にすることなく、今すぐ参加して、私たちと一緒に楽しくワイルドな可能性を自信を持って探求してください。 すぐに参加して、あなたにぴったりのデートの相手を見つけてください！ 世界中の人々があなたとの出会いを楽しみにしています！",
	"home.index.217hsv": "公式デート",
	"home.index.1qwq87": "ライブマッチング",
	"home.index.9lfic8": "デート投稿",
	"home.index.271b6x": "利用規約",
	"home.index.bgvt5h": "ニューヨーク",
	"home.index.nf5643": "クアラルンプール",
	"home.index.e28uee": "シンガポール",
	"home.index.3545k6": "マカオ",
	"home.index.ap5971": "ロンドン",
	"home.index.1553l4": "パリ",
	"home.index.76f1ej": "ソウル",
	"home.index.78e189": "東京",
	"home.index.151482": "進行中の日付......",
	"home.index.2gi1ut": "試合中......",
	"home.index.5v240w": "友達から連絡があり、家に来てエッチしてほしいと言われた。まだ肌はピチピチ。",
	"home.index.w4iy34": "招待コードをもらってよかった。 相談できる友達と長く付き合いたい。",
	"home.index.967989": "ベッドが上手で、ズッコンバッコンできて、性格もよくて、セックスもたくさんできる。",
	"home.index.c7g4i9": "サークルに紹介された友達、若くてハンサム、でもロマンチックでもあるyo。",
	"home.index.5m3i5e": "募集",
	"home.index.217s82": "価値の高い独身男性、穏やかでロマンチック、同じく人生を愛する独身女性、カップル、恋人とのコミュニケーションを楽しみにしています。",
	"home.index.5607du": "良い性格と良いベッドサイドマナー。",
	"home.index.957t61": "情熱的な出会いを求める独身女性を探している質の高い独身男性。",
	"home.index.t329l6": "人数が多いため予定は仮です。",
	"home.index.m5qq4k": "東京の独身男性をご招待 写真添付",
	"home.index.6tgwyb": "小さな女性のための温泉",
	"home.index.7nghkd": "異性の始まり",
	"home.index.yigf3d": "ニューヨーク優良カップル女性リード長い足おっぱい独身女性またはカップルを探しています",
	"home.index.5e6668": "初心者、私たちのカップルと同じ趣味を共有する良い友達を探しています。",
	"home.index.k14bj4": "オーストラリア女性とデートしたい",
	"home.index.knwc7w": "マレーシアの美人、育てるために逆説的なbimbos、通信するために別のグループ！",
	"home.index.11u846": "ジョホールバルの近くにカップル独身男性の連絡先",
	"home.index.73m14e": "最近男性の妻を交換する準備ができて",
	"home.index.360tn3": "マカオに位置し、男子大学生を探しています",
	"home.index.4du8f3": "近所の独身男性を探しています",
	"home.index.6kv7rl": "交換せずに同じ部屋で独身男性またはカップルを探している",
	"home.index.1myimn": "ロンドンっ子 貫通し、一緒に訓練するために2つの質の良い独身男性をお探しですか？",
	"home.index.53ng73": "温泉に行く準備ができて、すべての方法はありますか？",
	"home.index.25278v": "寝取られの長期募集、独身女性オーナーはこちらをご覧ください。",
	"home.index.27eju7": "スワップしたいけど、適当な人がいない。",
	"home.index.m62231": "スワップしてくれる若いカップルを探しています。",
	"home.index.ync69o": "今日はたまたま、検証に行くだけの空き時間があったので、予約を入れました。",
	"home.index.r65qkm": "質の高い独身男性 真摯にカップルと独身女性について; 独身男性は邪魔しない！",
	"home.index.873xdt": "独身男性パートタイムプロフェッショナルレディ",
	"home.index.z24jqu": "一緒に通信するためにグループに変態妻寝取られ交換仲良しの形成",
	"choose.index.ls1k2q": "マンコ揺らしグループ",
	"choose.index.32gq8s": "中国初の福祉ブランド",
	"choose.index.82os40": "会員の権利と利益のために、このウェブサイトは以下のものだけを表示します。",
	"choose.index.8s8018": "リソースを表示するだけで、定期的に更新されていない強さを示すために、より多くのリソースを予約する必要性などを問い合わせるには、優れた受信に連絡してください！",
	"choose.index.2lnyx2": "このプラットフォーム上のすべてのリソースは、本物の安全で信頼性の高い、全国が空気であり、同じ都市は、あなたのゼロ距離の出会いは、すぐに私達に参加し、あなたのお気に入りの彼女を選択する出会い系！",
	"choose.index.ri2y8x": "昵称: 欣妍",
	"choose.index.cwkc0u": "类型: 小清新/大学生/大长腿",
	"choose.index.x2s50k": "所在地区: 海南",
	"choose.index.3d213m": "服务项目: 激活可见",
	"choose.index.v7a484": "このプラットフォームは、最も本格的な周辺+ビジネス護衛+情熱的な都市であり、各ユーザーのプライバシーを確保するために、お客様は唯一の実名推薦の受付またはプラットフォームのシニアメンバーに連絡することによって参加することができます。",
	"choose.index.pf64q4": "リソースは何ですか",
	"choose.index.7476xv": "ネットフリックス、モデル、スチュワーデス、若いモデル、大学生、あなただけが考えることができない、プラットフォームが行うことはできません！",
	"choose.index.jf256n": "サービススコープ",
	"choose.index.46002f": "同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。",
	"choose.index.467v55": "殷リソースを振る",
	"choose.index.7s7egc": "デートのヒント",
	"choose.index.uuv568": "上海",
	"choose.index.dwlckt": "广州",
	"choose.index.3lf3p5": "深圳",
	"choose.index.84vvh2": "南京",
	"choose.index.7qoi95": "长沙",
	"choose.index.c41lr2": "重庆",
	"choose.index.j6l28t": "西安",
	"choose.profile.75l930": "予約",
	"choose.profile.dokp6j": "選択",
	"choose.profile.hl02h5": "カスタマーサービス",
	"game.fangpian.lgy8fw": "注意: 公式デートを申し込む会員は成人でなければなりません。 性別、年齢、住所、デートの目的、写真や動画など、提供されたすべての情報の真偽を厳しく審査します。 虚偽の情報が提供された場合、確認が取れ次第、サービスを停止させていただきます。 私たちは、あなたがここで完璧な経験を持つことができることを願っています：：",
	"game.fangpian.u23lm4": "1.ホテルなど安全な場所を選びましょう。 新しいパートナーと親密な性行為を行う前に、コンドームを使用するようにしてください。",
	"game.fangpian.ukb73x": "2.ハネムーン前の過度の薬物やアルコールの使用は、危険な性行為に及ぶ可能性を高めるので避けましょう。",
	"game.fangpian.l0u1zs": "あなたはオープンで冷静であるべきであることを親切に思い出させる。 楽しい夜を過ごすことにお互い合意したのですから、罪悪感や恐怖心を抱いてベッドに入るのはやめましょう。 会社や夜を楽しみ、官能的な欲望に身を任せましょう。 これは同意した大人同士の行為であり、何のしがらみもなく、あなたの心が望むことをしているのです。",
	"game.fangpian.60e346": "3.お互いに優しく接し、お互いが望まない限り、乱暴になりすぎないようにしましょう。 長い付き合いになるつもりはなくても、礼儀と敬意を払って損はない。",
	"game.fangpian.3ko9qh": "4.これはセックスですが、もしかしたら愛でもあるかもしれません。 興味本位で親しい交際相手を探そうとした人の約％が、本当の長期的な交際に至っている。 ここには無限の可能性がある。 最後のアドバイスは、親密なデートのプロセスでは、常に自分自身を保護し、親密なデートについてのご質問は、私たちのカスタマーサービスにお問い合わせください！",
	"game.fangpian.6e92kt": "P1デートガイド",
	"game.index.d231v6": "アダルト映画とビデオ",
	"game.index.g5k33o": "未成年の閲覧禁止",
	"game.index.iwtwx4": "お見合い",
	"game.index.342647": "公式お見合い",
	"game.index.i575s6": "近くの人",
	"game.index.c78g7g": "近くの人を見つける99+",
	"game.index.fwi3jl": "お見合いロビー",
	"game.index.71yn9v": "リフレッシュ成功",
	"game.index.4u3f73": "近くの友達を見つけよう",
	"game.index.761e68": "朝は近くの友達とつながり、昼はデート、夜はセックス！",
	"app.5dbbd25d.6986f9": "公式出会い系サイト",
	"app.5dbbd25d.t3n31s": "ニックネーム:",
	"app.5dbbd25d.2l12pm": "タイプ：",
	"app.5dbbd25d.6uw7sq": "場所：",
	"home.index(原始版).ti0qcm": "おすすめタスク",
	"home.index(原始版).0144qx": "もっと見る",
	"home.index(原始版).s9sv8w": "人気ランキング",
	"home.index(原始版).o8939n": "トップピック",
	"home.index(原始版).61l222": "プレイ:",
	"home.index(原始版).234pcj": "home.index(原始版).ti0qcm",
	"home.index(原始版).cv610w": "home.index(原始版).0144qx",
	"home.index(原始版).x8ahh0": "home.index(原始版).s9sv8w",
	"home.index(原始版).3co3d7": "home.index(原始版).o8939n",
	"home.index(原始版).g1fv9k": "home.index(原始版).61l222",
	"home.index(原始版).g1z862": "获取中......",
	"login.index.892k70": "サインイン",
	"login.index.np2eup": "パスワードをお忘れですか？",
	"login.index.xu2g7n": "アカウントをお持ちでないですか？ 今すぐ登録",
	"login.index.cbq7nt": "ユーザー名を入力してください",
	"login.index.dg52hc": "パスワードを入力してください",
	"login.index.5tovkx": "ユーザー名を入力してください！",
	"login.index.w7455e": "パスワードを入力してください！",
	"login.register.4byi5y": "登録",
	"login.register.c16ywh": "私は条約の「口座開設同意書」を理解し、同意します。",
	"login.register.b712un": "ユーザー名を入力してください",
	"login.register.koo7yi": "ログインパスワードを入力してください。",
	"login.register.wnqq6x": "招待コードを入力してください",
	"login.register.lqe02x": "招待コードを入力してください！",
	"login.register.ool966": "以下の口座開設同意書にチェックを入れてください！",
	"lottery.index.ybrr4t": "元=",
	"lottery.index.y0t8rj": "スイッチタスク",
	"lottery.index.s74n7k": "最初の距離",
	"lottery.index.9818u6": "期 締切",
	"lottery.index.eo78y8": "第&nbsp;",
	"lottery.index.9t1cod": "&nbsp;期&nbsp;",
	"lottery.index.6vs1ac": "公式協力プラットフォーム",
	"lottery.index.8d5uby": "このページの報酬データの舞台裏と公式協力プラットフォームのライブ報酬量のリアルタイムドッキングは、協力プラットフォームの要求に応じて、ライブルームへのアクセスを開くために報酬タスクを完了する必要があります！",
	"lottery.index.t57ug3": "個人的に報酬を与えないでください",
	"lottery.index.o7vuv8": "タスクリスト",
	"lottery.index.i68l2d": "利用可能な残高",
	"lottery.index.m9270s": "元",
	"lottery.index.4bguq4": "投げ銭",
	"lottery.index.1vq1f3": "現在の選択：",
	"lottery.index.xi7mas": "げ銭の金額",
	"lottery.index.1204o5": "総合ギフト",
	"lottery.index.ux6n71": "注",
	"lottery.index.8vfic3": "合計",
	"lottery.index.hw411g": "1注X",
	"lottery.index.63gm16": "オーダーをクリアする",
	"lottery.index.2p0mo5": "ギフトの確認",
	"lottery.index.541sdw": "発行番号",
	"lottery.index.i322dc": "正しくギフトを送る",
	"lottery.index.3lh226": "バラ",
	"lottery.index.8wwr3b": "花火",
	"lottery.index.67ri54": "ダブル",
	"lottery.index.711oli": "シングル",
	"lottery.index.2j7l8v": "金額を入力してください",
	"lottery.index.1e8722": "}],\r\n\t\t\t\t/*comment_0*/\r\n\t\t\t\tdomain: this.serviceUrl,\r\n\t\t\t\tchoose: {\r\n\t\t\t\t\t大: false,\r\n\t\t\t\t\t小: false,\r\n\t\t\t\t\t单: false,\r\n\t\t\t\t\t双: false,\r\n\t\t\t\t\t3: false,\r\n\t\t\t\t\t4: false,\r\n\t\t\t\t\t5: false,\r\n\t\t\t\t\t6: false,\r\n\t\t\t\t\t7: false,\r\n\t\t\t\t\t8: false,\r\n\t\t\t\t\t9: false,\r\n\t\t\t\t\t10: false,\r\n\t\t\t\t\t11: false,\r\n\t\t\t\t\t12: false,\r\n\t\t\t\t\t13: false,\r\n\t\t\t\t\t14: false,\r\n\t\t\t\t\t15: false,\r\n\t\t\t\t\t16: false,\r\n\t\t\t\t\t17: false,\r\n\t\t\t\t\t18: false\r\n\t\t\t\t},\r\n\t\t\t\tplaygame: false,\r\n\t\t\t\tshopping: false,\r\n\t\t\t\tisLoading: false,\r\n\t\t\t\tplay: {},\r\n\t\t\t\tlottery_peilv_list: {},\r\n\t\t\t\tlottery_list: {},\r\n\t\t\t\tlottery_get_one_list: {},\r\n\t\t\t\tactive: false,\r\n\t\t\t\tuserInfo: {},\r\n\t\t\t\tlottery: {},\r\n\t\t\t\tshanzi_1:",
	"lottery.index.4dv761": "未選択",
	"lottery.index.wj1832": "未選択",
	"lottery.index.emr765": "TWITデータギフト",
	"lottery.index.r99o57": "INSデータギフト",
	"lottery.index.816v46": "金額が正しくありません！",
	"lottery.index.v4781f": "番号を選択してください！",
	"lottery.index.dzt19u": "金額を入力してください！",
	"lottery.index.5ob55q": "残高不足のため、カスタマーサービスまでご連絡ください！",
	"lottery.index.40b27w": "選択されていません",
	"lottery.index.3g56m8": "アカウントオフライン",
	"lottery.index.dabq6w": "管理者にご連絡ください！",
	"lottery.index.350d0z": "開設に成功しました：",
	"mine.bindcard.0rd741": "引き出し口座情報を入力してください。",
	"mine.bindcard.hlpw55": "本名と出金パスワードが一致しない場合、出金することができません！",
	"mine.bindcard.27rxx8": "本名と出金パスワードが一致しない場合、出金することができません！",
	"mine.bindcard.a29l5j": "ペイジーカードの入力",
	"mine.bindcard.d3db08": "銀行カード番号",
	"mine.bindcard.h75343": "実際の銀行カード番号を入力してください。",
	"mine.bindcard.t280b5": "銀行名",
	"mine.bindcard.g0xqr0": "銀行を選択してください",
	"mine.bindcard.n2xqbk": "銀行カード番号を入力してください！",
	"mine.bindcard.l0836w": "銀行を選択してください",
	"mine.gamerecord.gyg63k": "ポイントを贈る：",
	"mine.gamerecord.vm634d": "ご注文の時間です：",
	"mine.gamerecord.1je2kw": "決済時間",
	"mine.gamerecord.ef47cf": "ギフト履歴",
	"mine.gamerecord.r0465i": "データは空です！",
	"mine.index.648c62": "チャージ",
	"mine.index.84v1y6": "引き出し",
	"mine.index.rd9s15": "マイ・ウォレット",
	"mine.index.91g6f5": "詳細",
	"mine.index.dnal90": "残高",
	"mine.index.4u1c6y": "個人明細",
	"mine.index.41zk6r": "口座詳細",
	"mine.index.2249jr": "パーソナルセンター",
	"mine.index.zdbsj2": "インフォメーション",
	"mine.index.5o511v": "オンラインカスタマーサービス",
	"mine.index.x4770x": "報酬を入力してください",
	"mine.index.0uzkf6": "専用受付カスタマーサービスにご連絡ください",
	"mine.index.6tqvf3": "専用受付カスタマーサービスにご連絡ください！",
	"mine.index.g6506q": "ログイン/登録",
	"mine.index.h8q3dv": "ログインして、より多くのサービスをお楽しみください！",
	"mine.infomation.u97p68": "アバター",
	"mine.infomation.651zoo": "アバターを選択",
	"mine.infomation.7y2kq1": "確定する",
	"mine.infomation.8566m9": "本名",
	"mine.infomation.481636": "性别",
	"mine.infomation.5n966t": "バインディング情報",
	"mine.infomation.gz68sf": "未設定",
	"mine.infomation.779q72": "男性",
	"mine.infomation.wn3u56": "女性",
	"mine.infomation.m4j6f5": "不明",
	"mine.infomation.87blm4": "バインド",
	"mine.infomation.o23023": "不明",
	"mine.notice.g1b1v4": "システム発表",
	"mine.personalreport.48pu74": "報奨金",
	"mine.personalreport.b6ss5e": "報酬金の計算式 : 報酬金 - ギフト額",
	"mine.personalreport.s676v4": "報酬金額",
	"mine.personalreport.jq4gw3": "入金額",
	"mine.personalreport.06x5mi": "引き出し金額",
	"mine.personalreport.7oe453": "完了金額",
	"mine.serviceonline.dra33t": "お問い合わせ",
	"mine.serviceonline.43e6gc": "24時間いつでも対応いたします。",
	"mine.serviceonline.mtajdv": "ハニーバジャーエンターテイメント",
	"mine.setbank.k07s6s": "地域によっては、出金に専用受付カスタマーサービスにお問い合わせください。",
	"mine.setbank.7v858g": "間違って記入した場合など、訂正が必要な場合は専用受付カスタマーサービスまでご連絡ください。",
	"mine.setbank.fps5qy": "出金口座情報",
	"mine.setbank.1rm776": "出金口座をバインドする前に、お名前をご記入ください！",
	"mine.setbank.i12kg6": "出金パスワードを設定し、銀行カードをご登録ください！",
	"mine.setloginpassword.x2r120": "保存",
	"mine.setloginpassword.96n6b7": "パスワード変更",
	"mine.setloginpassword.4fpm57": "旧パスワード",
	"mine.setloginpassword.as5sgk": "古いパスワードを入力してください",
	"mine.setloginpassword.6mpf6r": "新しいパスワード",
	"mine.setloginpassword.3hnf15": "新しいパスワードを入力してください",
	"mine.setloginpassword.h19hyg": "新しいパスワードをもう一度入力してください",
	"mine.setloginpassword.201cxi": "空欄を埋めてください！",
	"mine.setloginpassword.2n67dx": "2つのパスワードが一致しません",
	"mine.setname.62v4oj": "資金の安全のため、本名とお引出し口座名義は同じにしてください。",
	"mine.setname.j484fe": "本名を変更する",
	"mine.setname.c1v1k8": "氏名",
	"mine.setname.tf14kf": "本名を入力してください",
	"mine.setname.3li82o": "設定を繰り返さないでください！",
	"mine.setname.h54b6a": "お名前を入力してください！",
	"mine.setpaypassword.ef5r78": "マネーパスワードを入力してください",
	"mine.setpaypassword.xc8gt2": "もう一度パスワードを入力してください",
	"mine.setpaypassword.s80mhn": "提出（ていしゅつ",
	"mine.setpaypassword.0lb755": "マネーパスワードを設定する",
	"mine.setpaypassword.t3x39t": "すべての情報を入力してください",
	"mine.setpaypassword.7rse7c": "2つのパスワードは同じではありません！",
	"mine.setsex.mxlrcx": "性別の変更",
	"mine.setting.8l784k": "基本情報設定",
	"mine.setting.9c63br": "ログインパスワード",
	"mine.setting.h3r8gx": "ファンディングパスワード",
	"mine.setting.4mr9kl": "ログアウト",
	"mine.setting.o8817d": "設定",
	"mine.setting.2c76ay": "出金パスワードが設定されています。変更が必要な場合は、カスタマーサービスまでご連絡ください。",
	"mine.setting.70pi59": "設定する",
	"mine.withdraw.6k1i5s": "設定する",
	"mine.withdraw.h7j5b1": "出金額",
	"mine.withdraw.qns864": "すべて",
	"mine.withdraw.8t41f1": "1.到着時間：一般的な到着時間は約5分、最速は口座まで2分以内",
	"mine.withdraw.lnu01x": "説明",
	"mine.withdraw.6s6hcv": "残高：",
	"mine.withdraw.xs1wy1": "今すぐ現金を引き出す",
	"mine.withdraw.3tc38s": "出金センター",
	"mine.withdraw.t49c4c": "正しい金額を記入してください。",
	"mine.withdrawrecord.7kb5xg": "金額：-",
	"mine.withdrawrecord.z5l5nl": "内容：",
	"mine.withdrawrecord.0wnnta": "提出時間：",
	"mine.withdrawrecord.3r2c8o": "審査時間：",
	"video.index.4gy039": "会員ビデオ",
	"video.index.99w2t3": "これ以上",
	"video.playvideo.o4fm54": "サブプレイ",
	"video.playvideo.2xb18d": "動画を視聴するにはチャージが必要です。",
	"video.zhibojian.324hx5": "公式認定女神デポジット",
	"video.zhibojian.21nl01": "保証金の支払い",
	"video.zhibojian.wt3s2a": "地域:",
	"video.zhibojian.k7ws3w": "署名:",
	"video.zhibojian.3ygqv7": "ライブアクセス",
	"video.zhibojian.q213j3": "ヒント",
	"video.zhibojian.d822k2": "最初にアクセス許可を有効にしてください。",
	"router.index.e4rj5r": "側室選択リスト",
	"router.index.4v5i18": "側室選択の詳細",
	"router.index.1t0423": "ビデオ",
	"router.index.94x207": "ゲーム",
	"router.index.71w680": "性別を変更",
	"router.index.i88ne2": "支払いパスワード管理",
	"router.index.1m18s8": "ログインパスワード管理",
	"router.index.mx6b55": "ビデオ再生",
	"router.index.s10k30": "撤退",
	"router.index.1s76wn": "タスクレコード",
	"router.index.75l7l4": "不正行為対策ガイド",
	"router.index.b4y5k9": "短いビデオ",
	"router.index.675ja1": "ホームページの詳細"
}