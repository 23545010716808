import i18n from '../locales/index.js';
import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue'/* 首页 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Choose from '../pages/choose/index.vue'/* 选妃 */
import List from '../pages/choose/list.vue'/* 选妃列表 */
import Profile from '../pages/choose/profile.vue'/* 选妃详情 */
import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */
import Login from '../pages/login/index.vue'/* 登录 */
import Register from '../pages/login/register.vue'/* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* 基本信息 */
import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改姓名 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改提现密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
import PlayVideo from '../pages/video/PlayVideo'/* 视频播放页面 */
import Setbank from '../pages/mine/Setbank'/* 视频播放页面 */
import BindCard from '../pages/mine/BindCard'/* 绑定银行卡界面 */
import Withdraw from '../pages/mine/Withdraw'/* 绑定银行卡界面 */
import Personalreport from '../pages/mine/Personalreport'/* 个人表报 */
import GameRecord from '../pages/mine/GameRecord'/* 游戏记录 */
import WithdrawRecord from '../pages/mine/WithdrawRecord'/* 提现记录 */
import Fangpian from '../pages/game/fangpian.vue'/* 防骗 */
import Zhibojian from '../pages/video/zhibojian.vue'/* 直播间 */
import Livevideo from '../pages/video/livevideo.vue'/* 短视频 */
import Detail from '../pages/home/detail.vue'/* 首页详情 */


Vue.use(VueRouter)
const routes = [
    { path: '/', redirect: '/Home', component: Home, meta: { title: i18n.t('common.footer.142r87') } },
    { path: '/Home', name: 'home', component: Home, meta: { title: i18n.t('common.footer.142r87') } },
    { path: '/Choose', name: 'choose', component: Choose, meta: { title: i18n.t('choose.profile.dokp6j') } },
    { path: '/List', name: 'list', component: List, meta: { title: i18n.t('router.index.e4rj5r') } },
    { path: '/Profile', name: 'profile', component: Profile, meta: { title: i18n.t('router.index.4v5i18') } },
    { path: '/Mine', name: 'mine', component: Mine, meta: { title: i18n.t('common.footer.bwy7w8') } },
    { path: '/Video', name: 'video', component: Video, meta: { title: i18n.t('router.index.1t0423') } },
    { path: '/Game', name: 'game', component: Game, meta: { title: i18n.t('router.index.94x207') } },
    { path: '/Login', name: 'login', component: Login, meta: { title: i18n.t('login.index.892k70') } },
    { path: '/Register', name: 'register', component: Register, meta: { title: i18n.t('login.register.4byi5y') } },
    { path: '/ServiceOnline', name: 'ServiceOnline', component: ServiceOnline, meta: { title: i18n.t('mine.index.5o511v') } },
    { path: '/ServicePage', name: 'ServicePage', component: ServicePage, meta: { title: i18n.t('mine.index.5o511v') } },
    { path: '/Setting', name: 'Setting', component: Setting, meta: { title: i18n.t('mine.setting.o8817d') } },
    { path: '/Infomation', name: 'Infomation', component: Infomation, meta: { title: i18n.t('home.detail.chdtoq') } },
    { path: '/Setname', name: 'Setname', component: Setname, meta: { title: i18n.t('mine.setname.j484fe') } },
    { path: '/Setsex', name: 'Setsex', component: Setsex, meta: { title: i18n.t('router.index.71w680') } },
    { path: '/SetPayPassword', name: 'SetPayPassword', component: SetPayPassword, meta: { title: i18n.t('router.index.i88ne2') } },
    { path: '/SetLoginPassword', name: 'SetLoginPassword', component: SetLoginPassword, meta: { title: i18n.t('router.index.1m18s8') } },
    { path: '/Lottery', name: 'Lottery', component: Lottery },
    { path: '/Notice', name: 'Notice', component: Notice, meta: { title: i18n.t('mine.index.zdbsj2') } },
    { path: '/PlayVideo', name: 'PlayVideo', component: PlayVideo, meta: { title: i18n.t('router.index.mx6b55') } },
    { path: '/Setbank', name: 'Setbank', component: Setbank, meta: { title: i18n.t('mine.bindcard.a29l5j') } },
    { path: '/BindCard', name: 'BindCard', component: BindCard, meta: { title: i18n.t('mine.bindcard.a29l5j') } },
    { path: '/Withdraw', name: 'Withdraw', component: Withdraw, meta: { title: i18n.t('router.index.s10k30') } },
    { path: '/Personalreport', name: 'Personalreport', component: Personalreport, meta: { title: i18n.t('mine.index.4u1c6y') } },
    { path: '/WithdrawRecord', name: 'WithdrawRecord', component: WithdrawRecord, meta: { title: i18n.t('mine.withdraw.6k1i5s') } },
    { path: '/GameRecord', name: 'GameRecord', component: GameRecord, meta: { title: i18n.t('router.index.1s76wn') } },
    { path: '/Fangpian', name: 'Fangpian', component: Fangpian, meta: { title: i18n.t('router.index.75l7l4') } },
	{ path: '/Zhibojian', name: 'zhibojian', component: Zhibojian, meta: { title: i18n.t('common.footer.820xnr') } },
	{ path: '/Livevideo', name: 'livevideo', component: Livevideo, meta: { title: i18n.t('router.index.b4y5k9') } },
	{ path: '/Detail', name: 'detail', component: Detail, meta: { title: i18n.t('router.index.675ja1') } },
];

//生成路由实例
const router = new VueRouter({
    routes
});
router.beforeEach((to, from, next) => {         //修改标题路由配置加上这个
    if (to.matched[0].meta) {
        document.title = to.matched[0].meta.title
    }
    next()
});
// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
// router.beforeEach((to, from, next) => {
//   if (to.path === '/login') {
//     next();
//   } else {
//     let token = localStorage.getItem('token');
 
//     if (token === null || token === '') {
//       next('/login');
//     } else {
//       next();
//     }
//   }
// });

export default router