module.exports = {
	"common.footer.142r87": "首页",
	"common.footer.2984be": "密约",
	"common.footer.820xnr": "直播间",
	"common.footer.bwy7w8": "我的",
	"choose.list.8dj7a1": "北京",
	"choose.list.7oj6hs": "刷新成功！",
	"home.detail.6emj72": "岁,身高 ",
	"home.detail.7p7of1": "简介",
	"home.detail.n653wj": "密约反馈",
	"home.detail.787sgk": "详细地址：*****",
	"home.detail.5vs1b3": "联系方式：*****",
	"home.detail.chdtoq": "基本信息",
	"home.detail.q7k3xx": "所在地区",
	"home.detail.gd236k": "资料卡信息 ******",
	"home.detail.q56bck": "申请参加官方密约匹配,请联系专属接待客服",
	"home.detail.on80yp": "我想和TA约会",
	"home.detail.oht807": "请联系专属接待客服",
	"home.index.m831o8": "本页面只展示小部分参加官方密约匹配的会员信息，如需查询更多成人约会信息请联系官方客服人员",
	"home.index.nl3o64": "作为一个为解决成人心理/身体需求，而量身订造的开放性成人约会平台，将“约会”赋予了全新的概念",
	"home.index.h75t24": "昵称：",
	"home.index.b2d1n2": "简介：",
	"home.index.589121": "地区：",
	"home.index.72pckr": "已认证",
	"home.index.7521p4": "我们努力维护一个相互尊重，真实可靠的成人约会平台。内容审核团队会对所有布的约会招募信息经过严格审核后才会许发布，任可不符我们标准的信息特会被阻止以及删除。",
	"home.index.yzxn3c": "为了用户数据的机密性和完整性，我们取适当的技术和组织措施。根据我们的风险评估，我们特别实施准入控制，还实施访问控制以及定期检查。评估等措施的程序。",
	"home.index.102566": "我们有严格的服务保密机制，承诺保护本平台用户的隐私。您的隐私和安全对我们非常重要，我们有私人资料选项，不与第三方共享信息，一旦您的信息资料获得我们验证团队的批准和验证（目的是确保我们平台不存在虚假个人资料、骗子、性工作者和记者等)，您就可以开始在P1CLUB开启一段不一样的人生！",
	"home.index.ftsut5": "成为P1CLUB会员有许多好处?",
	"home.index.y75fd5": "您不必花费大量时间和精力来维持这种关系，您可以在最方便的时候见到对方。",
	"home.index.h83si8": "您可以不受任何关系的限制相互满足心理身体的需求，不用必须承担任何的社会关系限制，您可以准确决定您想要的方式。",
	"home.index.bupuo6": "您不会再感到抓独了，每当您希望有人陪伴、出差、度假时，您只需邀请您的P1CLUB客服为您匹配或自己在P1社圈通过交流互动、发贴招募您希望的伴侣即可。",
	"home.index.015xt1": "你会感觉更年轻，您可以选择一个比您年轻的、或是在某些生活、工作方面上可以帮助到您的PFriends，为您的生活带来更多的收获和欢乐。",
	"home.index.241j69": "您不必浪费时间和精力维持这样的关系。当您认为不再适合时，可以告诉对方结束你们之间的关系。",
	"home.index.r764wg": "我为什么要加入P1CLUB",
	"home.index.5g8uu8": "体验与志同道合的成年人建立更多元化联系的快感，无需任何初始承诺。慢慢来，浏览我们广泛的社圈信息，当您准备好时候，就可以解锁一个令人充满兴奋，有着无限可能性的世界。有了P1CLUB打开新的生活方式！",
	"home.index.p8dvv7": "在这里，您一定会找到适合您的人。 您能够从成干上万的真实个人资料中进行选择，使用我们的搜家功能按位置、距离范围、年龄范围、爱好习惯等进行搜索。",
	"home.index.dkx49a": "通过P1CLUB发现成人约会的全新定义！凭借超过12年的行业领先地位，我们很自豪能为您提供满意的服务。我们对我们的平台非常有信心，我们允许您免费浏览和探索我们的部分社圈信息。",
	"home.index.q8toq9": "P1CLUB拥有超过12年的互联网成人约会经验，是成人交友约会中最值信得信赖的品牌。",
	"home.index.4i6p6e": "我们的数据库中有着上百万的会员用户，我们非常自豪拥有多元化的会员基础，P1CLUB汇聚了来自世界各地的优质用户，以满足大家寻求心中理想的伴侣关系。\n到目前为止已经有数十万计用户在我们的开放性社圈找到了理想的伴侣关系，并与大家分享了他们许多有趣的故事。",
	"home.index.q5v662": "因此，不要浪费您的时间，立即加入P1CLUB，充满信心地与我们探索有趣而狂野的无限可能。加入我们立即为您寻找到完美的约会伴侣！来自全世界各地的人都在期待与您会面！",
	"home.index.217hsv": "官方密约",
	"home.index.1qwq87": "实时配对",
	"home.index.9lfic8": "密约投稿",
	"home.index.271b6x": "服务条款",
	"home.index.bgvt5h": "纽约",
	"home.index.nf5643": "吉隆坡",
	"home.index.e28uee": "新加坡",
	"home.index.3545k6": "澳门",
	"home.index.ap5971": "伦敦",
	"home.index.1553l4": "巴黎",
	"home.index.76f1ej": "首尔",
	"home.index.78e189": "东京",
	"home.index.151482": "密约进行中......",
	"home.index.2gi1ut": "密约匹配中......",
	"home.index.5v240w": "联系好P友了,约好时间叫我去她家操她，皮肤还是很紧致",
	"home.index.w4iy34": "庆祝顺利获得邀请码，欢迎 聊的来的朋友长期交往",
	"home.index.967989": "床上满分 可一字马 性格超好 氵超多",
	"home.index.c7g4i9": "朋友介绍入圈的，年轻帅气，还很浪漫哟。",
	"home.index.5m3i5e": "招募优质单男",
	"home.index.217s82": "SGP高颜值素质单男   温柔浪漫  期待与同样热爱生活的 单女  夫妻 情侣  交流娱乐",
	"home.index.5607du": "P友床上满分 性格超好",
	"home.index.957t61": "KL素质单男求单女少妇相约激情，喜欢露出的更好",
	"home.index.t329l6": "计划多p,人数暂定",
	"home.index.m5qq4k": "诚邀东京优质单男活动, 图片附件",
	"home.index.6tgwyb": "小女人活动之温泉",
	"home.index.7nghkd": "异性SPA开始 3P之旅",
	"home.index.yigf3d": "纽约优质情侣 女主长腿D胸找单女或者情侣.",
	"home.index.5e6668": "初入P1，求找到和我们夫妻志同道合的好朋友",
	"home.index.k14bj4": "只想约澳州的小姐姐",
	"home.index.knwc7w": "大马美女、反差婊养成，另建群交流！",
	"home.index.11u846": "新山附近夫妻单男联系",
	"home.index.73m14e": "近日准备交换的人妻",
	"home.index.360tn3": "坐标澳门，寻找在校男大学生",
	"home.index.4du8f3": "找附近的单男",
	"home.index.6kv7rl": "找单男或者夫妻同房不换",
	"home.index.1myimn": "伦敦P友party 寻找两位优质单男一起插入调教",
	"home.index.53ng73": "准备去温泉，有没有一路的",
	"home.index.25278v": "长期招收绿帽，单女S女主看过来",
	"home.index.27eju7": "一直想交换，但没有合适的，哪位大神能拉我入群",
	"home.index.m62231": "求交换，年轻情侣夫妻来~",
	"home.index.ync69o": "今天正好有空闲，正好去验证一下，就约了一下。见面一看，胸不大不小，身材很好，和照片一致。",
	"home.index.r65qkm": "素质单男 真诚约夫妻及单女；单男勿扰！！",
	"home.index.873xdt": "单男兼职专业女士spa",
	"home.index.z24jqu": "组建淫妻绿帽3p交换同好进群一起交流",
	"choose.index.ls1k2q": "抖阴集团",
	"choose.index.32gq8s": "华人第一福利品牌",
	"choose.index.82os40": "为了会员权益，网站只展示",
	"choose.index.8s8018": "资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待",
	"choose.index.2lnyx2": "本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧",
	"choose.index.ri2y8x": "昵称: 欣妍",
	"choose.index.cwkc0u": "类型: 小清新/大学生/大长腿",
	"choose.index.x2s50k": "所在地区: 海南",
	"choose.index.3d213m": "服务项目: 激活可见",
	"choose.index.v7a484": "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。",
	"choose.index.pf64q4": "有哪些资源?",
	"choose.index.7476xv": "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到",
	"choose.index.jf256n": "服务范围?",
	"choose.index.46002f": "同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。",
	"choose.index.467v55": "抖阴资源",
	"choose.index.7s7egc": "约炮心得",
	"choose.index.uuv568": "上海",
	"choose.index.dwlckt": "广州",
	"choose.index.3lf3p5": "深圳",
	"choose.index.84vvh2": "南京",
	"choose.index.7qoi95": "长沙",
	"choose.index.c41lr2": "重庆",
	"choose.index.j6l28t": "西安",
	"choose.profile.75l930": "预约",
	"choose.profile.dokp6j": "选妃",
	"choose.profile.hl02h5": "联系专属接待客服",
	"game.fangpian.lgy8fw": "注意：申请参加P1CLUB官方密约匹配的会员必须是成年人。我们将会严格的审查所有信息的真实性，性别、年龄、地址、密约目的或相片视频等相关资料。若提供虚假信息，一经查实，我们将终止服务。希望在这里您可以得到完美的体验，我们建议您：",
	"game.fangpian.u23lm4": "1.选择一个安全的场地，例如酒店。与新伴侣密约进行性活动之前请尽量使用安全套。",
	"game.fangpian.ukb73x": "2.蜜月前避免过度的使用药物或酒精，因为它们会增加您进行高风险性行为的可能性。",
	"game.fangpian.l0u1zs": "友情提醒：您应该保持开放、冷静。既然彼此都同意度过一个愉快的夜晚，就不要带着愧疚或恐惧上床睡觉了。享受陪伴、夜晚，并屈服于您的感官欲望。这是两个成年人之间自愿发生的行为，没有任何附加条件，做您们内心想做的事。",
	"game.fangpian.60e346": "3.过程中对彼此温柔，不要太粗暴，除非您们都喜欢这样。在行动之前花些时间进行交谈，即使您不打算建立长期关系，保持礼貌和尊重也没有什么坏处。",
	"game.fangpian.3ko9qh": "4.这是性，但也许也是爱？大约20%的人以好奇而尝试寻找密约伴侣，最终建立了真正的长期关系。在这里充满了无限的可能性。最后的建议是：密约过程中一定保护好自己，任何关于密约的问题，您都可以联系我们的客服为您解决！",
	"game.fangpian.6e92kt": "P1密约指南",
	"game.index.d231v6": "成人影视",
	"game.index.g5k33o": "未成年禁止观看",
	"game.index.iwtwx4": "密约匹配",
	"game.index.342647": "官方密约匹配进行中",
	"game.index.i575s6": "附近的人",
	"game.index.c78g7g": "为您发现附近人密约99+",
	"game.index.fwi3jl": "密约大厅",
	"game.index.71yn9v": "刷新成功",
	"game.index.4u3f73": "为您发现附近密约好友",
	"game.index.761e68": "和附近的好友上午联系，下午约会，晚上做爱做的事情~",
	"app.5dbbd25d.6986f9": "官网密约",
	"app.5dbbd25d.t3n31s": "昵称:",
	"app.5dbbd25d.2l12pm": "类型：",
	"app.5dbbd25d.6uw7sq": "所在地区：",
	"home.index(原始版).ti0qcm": "推荐任务",
	"home.index(原始版).0144qx": "查看更多",
	"home.index(原始版).s9sv8w": "人气排行",
	"home.index(原始版).o8939n": "热门推荐",
	"home.index(原始版).61l222": "播放:",
	"home.index(原始版).234pcj": "home.index(原始版).ti0qcm",
	"home.index(原始版).cv610w": "home.index(原始版).0144qx",
	"home.index(原始版).x8ahh0": "home.index(原始版).s9sv8w",
	"home.index(原始版).3co3d7": "home.index(原始版).o8939n",
	"home.index(原始版).g1fv9k": "home.index(原始版).61l222",
	"home.index(原始版).g1z862": "获取中......",
	"login.index.892k70": "登录",
	"login.index.np2eup": "忘记密码？",
	"login.index.xu2g7n": "没有账户？马上注册",
	"login.index.cbq7nt": "请输入用户名",
	"login.index.dg52hc": "请输入密码",
	"login.index.5tovkx": "请输入用户名！",
	"login.index.w7455e": "请输入密码！",
	"login.register.4byi5y": "注册",
	"login.register.c16ywh": "我已经知晓并同意“开户协议”各项条约",
	"login.register.b712un": "请输入用户名(6-12个英文字母或数字)",
	"login.register.koo7yi": "请输入登录密码(6-12个英文字母或数字)",
	"login.register.wnqq6x": "请输入邀请码",
	"login.register.lqe02x": "请输入邀请码！",
	"login.register.ool966": "请勾选下方开户协议！",
	"lottery.index.ybrr4t": "元=",
	"lottery.index.y0t8rj": "切换任务",
	"lottery.index.s74n7k": "距 第",
	"lottery.index.9818u6": "期 截止",
	"lottery.index.eo78y8": "第&nbsp;",
	"lottery.index.9t1cod": "&nbsp;期&nbsp;",
	"lottery.index.6vs1ac": "官方合作平台",
	"lottery.index.8d5uby": "本页面打赏数据后台与官方合作平台直播间打赏额实时对接，应合作平台要求，需完成打赏任务后开通直播间权限",
	"lottery.index.t57ug3": "请勿私自进行打赏",
	"lottery.index.o7vuv8": "任务单",
	"lottery.index.i68l2d": "可用余额",
	"lottery.index.m9270s": "元",
	"lottery.index.4bguq4": "打赏",
	"lottery.index.1vq1f3": "当前选号：",
	"lottery.index.xi7mas": "打赏金额：",
	"lottery.index.1204o5": "总共打赏",
	"lottery.index.ux6n71": "注",
	"lottery.index.8vfic3": "合计",
	"lottery.index.hw411g": "1注X",
	"lottery.index.63gm16": "清空订单",
	"lottery.index.2p0mo5": "确认打赏",
	"lottery.index.541sdw": "期号",
	"lottery.index.i322dc": "正确打赏",
	"lottery.index.3lh226": "玫瑰",
	"lottery.index.8wwr3b": "烟花",
	"lottery.index.67ri54": "双份",
	"lottery.index.711oli": "单份",
	"lottery.index.2j7l8v": "请输入金额",
	"lottery.index.1e8722": "}],\r\n\t\t\t\t\r\n\t\t\t\tdomain: this.serviceUrl,\r\n\t\t\t\tchoose: {\r\n\t\t\t\t\t大: false,\r\n\t\t\t\t\t小: false,\r\n\t\t\t\t\t单: false,\r\n\t\t\t\t\t双: false,\r\n\t\t\t\t\t3: false,\r\n\t\t\t\t\t4: false,\r\n\t\t\t\t\t5: false,\r\n\t\t\t\t\t6: false,\r\n\t\t\t\t\t7: false,\r\n\t\t\t\t\t8: false,\r\n\t\t\t\t\t9: false,\r\n\t\t\t\t\t10: false,\r\n\t\t\t\t\t11: false,\r\n\t\t\t\t\t12: false,\r\n\t\t\t\t\t13: false,\r\n\t\t\t\t\t14: false,\r\n\t\t\t\t\t15: false,\r\n\t\t\t\t\t16: false,\r\n\t\t\t\t\t17: false,\r\n\t\t\t\t\t18: false\r\n\t\t\t\t},\r\n\t\t\t\tplaygame: false,\r\n\t\t\t\tshopping: false,\r\n\t\t\t\tisLoading: false,\r\n\t\t\t\tplay: {},\r\n\t\t\t\tlottery_peilv_list: {},\r\n\t\t\t\tlottery_list: {},\r\n\t\t\t\tlottery_get_one_list: {},\r\n\t\t\t\tactive: false,\r\n\t\t\t\tuserInfo: {},\r\n\t\t\t\tlottery: {},\r\n\t\t\t\tshanzi_1:",
	"lottery.index.4dv761": "未选择",
	"lottery.index.wj1832": "火箭",
	"lottery.index.emr765": "TWIT数据打赏",
	"lottery.index.r99o57": "INS数据打赏",
	"lottery.index.816v46": "金额错误！",
	"lottery.index.v4781f": "请选号！",
	"lottery.index.dzt19u": "请填写金额！",
	"lottery.index.5ob55q": "余额不足，请联系客服充值！",
	"lottery.index.40b27w": "未选中",
	"lottery.index.3g56m8": "账号下线",
	"lottery.index.dabq6w": "请联系管理员领取该任务!",
	"lottery.index.350d0z": "开奖成功，期号：",
	"mine.bindcard.0rd741": "请输入您的提款账户信息",
	"mine.bindcard.hlpw55": "尊敬的用户,为了保障您的资金安全,请您确认您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款",
	"mine.bindcard.27rxx8": "确认提现",
	"mine.bindcard.a29l5j": "填写收款卡",
	"mine.bindcard.d3db08": "银行卡号",
	"mine.bindcard.h75343": "请输入真实银行卡号",
	"mine.bindcard.t280b5": "银行名称",
	"mine.bindcard.g0xqr0": "请选择银行",
	"mine.bindcard.n2xqbk": "请输入银行卡号！",
	"mine.bindcard.l0836w": "请选择银行！",
	"mine.gamerecord.gyg63k": "打赏积分：",
	"mine.gamerecord.vm634d": "下单时间：",
	"mine.gamerecord.1je2kw": "结算时间：",
	"mine.gamerecord.ef47cf": "打赏记录",
	"mine.gamerecord.r0465i": "数据为空！",
	"mine.index.648c62": "充值",
	"mine.index.84v1y6": "提款",
	"mine.index.rd9s15": "我的钱包",
	"mine.index.91g6f5": "详情",
	"mine.index.dnal90": "余额",
	"mine.index.4u1c6y": "个人报表",
	"mine.index.41zk6r": "账户明细",
	"mine.index.2249jr": "个人中心",
	"mine.index.zdbsj2": "信息公告",
	"mine.index.5o511v": "在线客服",
	"mine.index.x4770x": "请完成任打赏后进入",
	"mine.index.0uzkf6": "因地区不同,请联系专属接待客服",
	"mine.index.6tqvf3": "请联系专属接待客服!",
	"mine.index.g6506q": "登录/注册",
	"mine.index.h8q3dv": "登录可享受更多服务！",
	"mine.infomation.u97p68": "头像",
	"mine.infomation.651zoo": "选择头像",
	"mine.infomation.7y2kq1": "确定",
	"mine.infomation.8566m9": "真实姓名",
	"mine.infomation.481636": "性别",
	"mine.infomation.5n966t": "绑定信息",
	"mine.infomation.gz68sf": "未设置",
	"mine.infomation.779q72": "男",
	"mine.infomation.wn3u56": "女",
	"mine.infomation.m4j6f5": "未知",
	"mine.infomation.87blm4": "已绑定",
	"mine.infomation.o23023": "无",
	"mine.notice.g1b1v4": "系统公告",
	"mine.personalreport.48pu74": "奖励金",
	"mine.personalreport.b6ss5e": "奖励金计算公式 : 奖励金 - 打赏金额",
	"mine.personalreport.s676v4": "奖励金额",
	"mine.personalreport.jq4gw3": "充值金额",
	"mine.personalreport.06x5mi": "提款金额",
	"mine.personalreport.7oe453": "完成金额",
	"mine.serviceonline.dra33t": "联系",
	"mine.serviceonline.43e6gc": "全天7 * 24小时竭诚为您服务",
	"mine.serviceonline.mtajdv": "蜜獾娱乐",
	"mine.setbank.k07s6s": "因地区不同,请联系专属接待客服进行提款",
	"mine.setbank.7v858g": "提示:如填写有误需修改,请联系专属接待客服",
	"mine.setbank.fps5qy": "提款账户信息",
	"mine.setbank.1rm776": "请设置姓名后在绑定提款账户！",
	"mine.setbank.i12kg6": "请设置提现密码后再绑定银行卡！",
	"mine.setloginpassword.x2r120": "保存",
	"mine.setloginpassword.96n6b7": "修改登录密码",
	"mine.setloginpassword.4fpm57": "旧密码",
	"mine.setloginpassword.as5sgk": "请输入您的旧密码",
	"mine.setloginpassword.6mpf6r": "新密码",
	"mine.setloginpassword.3hnf15": "请输入您的新密码",
	"mine.setloginpassword.h19hyg": "请再次输入您的新密码",
	"mine.setloginpassword.201cxi": "请填写完整！",
	"mine.setloginpassword.2n67dx": "两次密码输入不一致",
	"mine.setname.62v4oj": "为了您的资金安全,真实姓名需要与提款账户姓名一致",
	"mine.setname.j484fe": "修改真实姓名",
	"mine.setname.c1v1k8": "姓名",
	"mine.setname.tf14kf": "请输入真实姓名",
	"mine.setname.3li82o": "请勿重复设置！",
	"mine.setname.h54b6a": "请输入姓名！",
	"mine.setpaypassword.ef5r78": "请输入资金密码",
	"mine.setpaypassword.xc8gt2": "请再次输入资金密码",
	"mine.setpaypassword.s80mhn": "提交",
	"mine.setpaypassword.0lb755": "设置资金密码",
	"mine.setpaypassword.t3x39t": "请填写完整",
	"mine.setpaypassword.7rse7c": "两次密码不一致！",
	"mine.setsex.mxlrcx": "性别修改",
	"mine.setting.8l784k": "基本信息设置",
	"mine.setting.9c63br": "登录密码",
	"mine.setting.h3r8gx": "资金密码",
	"mine.setting.4mr9kl": "退出登录",
	"mine.setting.o8817d": "设置",
	"mine.setting.2c76ay": "提现密码已设置，需要修改请联系客服",
	"mine.setting.70pi59": "已设置",
	"mine.withdraw.6k1i5s": "提现记录",
	"mine.withdraw.h7j5b1": "提现金额",
	"mine.withdraw.qns864": "全部",
	"mine.withdraw.8t41f1": "1.到账时间：一般到账时间在5分钟左右，最快2分钟内到账",
	"mine.withdraw.lnu01x": "说明",
	"mine.withdraw.6s6hcv": "余额：",
	"mine.withdraw.xs1wy1": "马上提现",
	"mine.withdraw.3tc38s": "提现中心",
	"mine.withdraw.t49c4c": "请填写正确的金额",
	"mine.withdrawrecord.7kb5xg": "金额：-",
	"mine.withdrawrecord.z5l5nl": "说明：",
	"mine.withdrawrecord.0wnnta": "提交时间：",
	"mine.withdrawrecord.3r2c8o": "审核时间：",
	"video.index.4gy039": "会员视频",
	"video.index.99w2t3": "没有更多了",
	"video.playvideo.o4fm54": "次播放",
	"video.playvideo.2xb18d": "请充值后观看视频！",
	"video.zhibojian.324hx5": "官方认证女神",
	"video.zhibojian.21nl01": "已缴纳200000保证金",
	"video.zhibojian.wt3s2a": "地区:",
	"video.zhibojian.k7ws3w": "签名:",
	"video.zhibojian.3ygqv7": "获取直播权限",
	"video.zhibojian.q213j3": "温馨提示",
	"video.zhibojian.d822k2": "请先激活权限",
	"router.index.e4rj5r": "选妃列表",
	"router.index.4v5i18": "选妃详情",
	"router.index.1t0423": "视频",
	"router.index.94x207": "游戏",
	"router.index.71w680": "修改性别",
	"router.index.i88ne2": "支付密码管理",
	"router.index.1m18s8": "登录密码管理",
	"router.index.mx6b55": "视频播放",
	"router.index.s10k30": "提现",
	"router.index.1s76wn": "任务记录",
	"router.index.75l7l4": "防骗指南",
	"router.index.b4y5k9": "短视频",
	"router.index.675ja1": "首页详情"
}