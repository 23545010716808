<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="" />
		<div class="convention-item">
			<van-swipe :autoplay="3000" lazy-render class="my-swipe" :show-indicators="false">
				<van-swipe-item v-for="image in images" :key="image"><img :src="image" class="banner_img" /></van-swipe-item>
			</van-swipe>
			<div class="describe">
				<ul>
					<li>
						<div><P1 class="CLUB"></P1></div>
						<div></div>
						<div>P1 CLUB</div>
						<div></div>
					</li>
					<li>
						
						<b style="font-size: 16px; color: #f8c693"></b>{{$t('home.index.m831o8')}}</li>
				</ul>
				<div>{{$t('home.index.nl3o64')}}</div>
			</div>

			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab :title="$t('home.index.217hsv')">
					<!-- <div class="card">本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。</div> -->
					<!-- <div class="address">
						<van-row type="flex" justify="" gutter="65" v-for="(val, key) in addlist" :key="key">
							<van-col span="" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row>
					</div> -->
					<div class="xuanfei" v-for="(val, key) in xuanfeilist" :key="key" @click="xfts(val.id)">
						<div class="left-div">
							<div>ID:{{ val.itemid }}</div>
							<div>
								<ul>
									<li>{{$t('home.index.h75t24')}}{{ val.nickname }}</li>
									<li>{{$t('home.index.b2d1n2')}}{{ val.typelabel }}</li>
									<li>{{$t('home.index.589121')}}{{ val.region }}</li>
									<li>{{$t('home.detail.5vs1b3')}}</li>
								</ul>
							</div>
							<div>
								<ul>
									<li>
										<img
											src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABIRJREFUaEPtWl1oHFUU/s7Miq8aERFFihbRh7agVuhDsS2CL75opWClah+Umuw9s6EiRgsdENQHze7cu6tEReNPIqJGwRfR+gMqilIfbEQRxArqm4l9a6AzR27ZDZNkN/Ozm92S2QvzsnO+853vnjvn/i2hYI0KphdDwZs948MMDzO8yXpgOKQ3WULXyBlmeJjhnD1QrVavdRxnGxFtA2CfawBcEnus5/9izx8ATorIz67r/lIul//MSb0urKdDWmt9MREdFJGDAG7vMuATRDQrIrPMvNSlr2V4TwTX6/XLRKQMwIq9vlfBWT9E9FsURW+7rmvK5fK/3fruSrDv+6WRkREr1D7XdRtMAv53APWFhYW67/vn8nLlFhwEwc1E9BKAm/KS58T9CGCCmT/Jg88l2BizT0Q+y0PYK4yIPOR53itZ/WUWbIxRIqKzEm2Q/ZPM/HQW35kEB0HgE9HxLAQbbSsi93ue92ZantSCjTGPi8gzaR2vY2enmHkAlzfn5q5dEtEBpdS7aRylEmyMsdPNTBqHHWxeBfCViJzyPO9ky6ZWq13hOM4OEdlORI81OyEXTVrRiYKr1eoW13W/ALAlVyTAfmaeS8I2Go2tYRi+DGBPkm2790T0t13sKKV+XQ+fKFhr/RqAB3ME8Z0NPusqqZuiSEQfKKXuzi1Ya22FWsFZ2zlmvmg1qDl37xGRqxzHORtF0fzi4uKc7/tn47Za60cAvJCV1NqLyBOe53WsNR0zrLW+2n53OYfyEWaeagVsfdmpjIjuaiPiDIBjzFyPvwuC4D0i2p9HtOu6t46Njf3Qduh3cqi1fg7A0RyEU8x8pIWbnJzcWiqVPk5aehLRs0qpiRau0WjcGIbhNwAuzRoDEb2ulGr7GbbNcK1W2+k4zrcA3KxkpVJpx+jo6E+x7H4J4LY0fqIoOlSpVN5q2RpjZpo7rzTwFTYiss/zPFtsV7S2goMgmLHbvMwswBlmtnve8y0IggNE9E4GP/PMbPfO55vW+hiApzLgl01F5H3P8+5JFKy1tvvYT/OQAPiamXfHAn4DwKEsvohou1LqlMXU6/U7oyj6KAt+le0dqzcZazIcBMHDRLRccDKSvcjMozHBtnDcksWHLWxKqQ8tZmpq6sqlpaV/suDjtkTESimz4rfVzgonuHBDulls8s6BF1LRmvU8777EotUUvJeIPs/z7Vwg01IYRdGuSqWyZvHRcaVljJkWkQdyiB74wgPA88z8aLvYOwpuNBo7wzD8PodgCxnk0vI0gN3M/Fcmwc2hPUFEmY5QmiQD2zwAOMzM050Slbg9NMbMiUi7RX9S8vu+PQQwzcyH1wssjeAbAJywW7okhR3e9+UAAMDpMAz3jo+P2yHdsSUKtshCHfG0uqpQh3gx0cdFxM85tDcEtmHHtDHRxTmIj4kuzlVLS3ShLtNaogt1XRqvQPZCPAxD5TjOvZv6Qnx12S3MXx7azTeF+FPLhky0PXaaamnZY86BuhsKHmj394F8mOE+dPJAKYYZHmj394F8mOE+dPJAKQqX4f8BYPRTW5RGL5kAAAAASUVORK5CYII="
											alt=""
										/>
										<span>{{ val.views }}</span>
									</li>
									<li>
										<img
											src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABOBJREFUaEPtmmtoHFUUx///2SiKQdsKRqUoBfWD+kGREltRqlZBK6LQRLRaK4So+ZLt3DtJfEEQIjRzZ1O1vioSXwS0KC3EkrZY+sUg2hoQRNT6QFFo0agItWqyR2aSLNvsc3ZnNskm9+PuOed/fveeO3PPzBCLbHCR8WIJuN5XfGmFl1a4zmag4pIW110HYB3IJlCapubFOgCRw9T606jmSXp7T8c5Z90C4VoImyByPogTAL+AJd9BrC9p24fL1QsFLMZcAwvtENwF4LwiIkcg2Emtd5abyGw7GRw8A+PjfYBsAbCieBwOQeRNar2vlF7ZwJIyr0DQXirgrP+PAOleqq7hMH5B9VjsA7A2jB+A16n0Q8V8ygIWz7wLoCWkeJY5+6jUk+X4i+d2AwFsohz7PDY/UOlVhXxLAlcPOy1NvA9Y99O2/y6UjKTMOxC0Vgia7XaUSl+aL05R4MhgM8qyF0w8QNsez9mznhkE4O/XqMYuKp0zeQWBxXNfBvhwVOqZOMRBwNpM2/555jcx5gUQHZFrCZLU+tnsuHmBJZW6ApIeA3Ba5ElMBRwFrQdp20fFc12AOiad75GWa+k4x2fi5wf2XA+gHVMSM2HHABkG+FS8OuygUi8VBBbXXQWLnwFYFm8itYou71E5GwsDe+7T8c96rWADnd+pdObgklPS4plPAKyuaUpxi1mJ5dy69Q9fJh/wsRLHxrjTiz5+Q/oydnZ9kwMsO3aci39O/hq94lxH5HVUajQXeKD/KqQt/3ZUX0NwN7XenQvseXcCsqe+aH0aaadyXs0FNqYTxPa6AyaeoK2fyQVOuQ6E/fUHLPfQdvyO79SrtHj9WwDLP8TX10g0XMxk8sd8e/h2QD6oK1rBt9T6krwnLUmlVkDSv9UVMDhEpTblBQ6uZ54ZAnBv3UDPahFzT1rGbALxdt0Ac7KZdrd/XA5G/vbQmI9BNNcB9AEqfWs2R4F+uH8zYL2x8IHTHVRdmV644ApP7+WDAG5cwNDjSDRczmTSb4Yyo/AzrQGzEWnsWrjA8hqV0zY7/+JPLVNmGIINCxOaG6jU3nDArrsGFkcAnL2woLmNSvXky7n0g3hj2kAEncaCGMQhrLxoPVtbJysCDi5gxmwH0TnvgUVOgtbNM81+5cAtLQk0N4+AWD+/oUVROaliOZYs6Rlncd0r4b8fIvO+s5n7ieBzVKpkFZYNHJS2D21xP4AL5h7wlJvrIdq6rDNDKOAA+vltF+LfxFcAGucN9H8Ty9jT82c5+YQGDqBTqTMh6RPlCMRuQ6uZtp1pDkrpVQSc2dfG/QnkylIisf1Pa3WY7zv8PKoCnlptMwrBmtig8gc+jkTDDUwm/a0ValQNHEBX/UlEqJzHMDF5B7u7fwnlNW0cCXANoYcxMXkfu7v/qgQ2kpLOFhbPfRHgo5UmU9xPDJXjVBs7shXOXMgGTBsmpR/k8mqTm/Y/BoFDrd+KIl7kwEF5+x+wAf0gbqoqSXI/rLTDpPN5VXGynGMBDqB7ey00NvrQqrJkoynh2dqxAWdK3JjbQD4GyPXlgcsIYBkq9WF59uGsYgfOgHueD/14kSPp1wB90Fh775oBT926vKtBeSTrm03/XvoRKPuQ5h5qHfvL+JoChyu+eKyXgOOZ1/kTdWmF589axJPJolvh/wF8iXZMtgf/PgAAAABJRU5ErkJggg=="
											alt=""
										/>
										<span>{{ val.collection }}</span>
									</li>
								</ul>
							</div>
						</div>
						<div class="right-div"><img :src="domain + val.flagimg" alt="" /></div>
						<i class="sign-i">{{$t('home.index.72pckr')}}</i>
					</div>
				</van-tab>
				<van-tab :title="$t('home.index.1qwq87')">
					<div class="scroll-cmp">
						<van-swipe :height="52" vertical :autoplay="4000" :duration="1000" :show-indicators="false">
							<van-swipe-item v-for="(val, key) in peiduilist" :key="key">
								<div>
									<span>{{ val.name }}</span>
									<span>{{ val.tel }}</span>
								</div>
								<div>
									<span>{{ val.title }}</span>
								</div>
							</van-swipe-item>
						</van-swipe>
					</div>
				</van-tab>
				<van-tab :title="$t('home.index.9lfic8')">
					<div class="scroll-cmp">
						<van-swipe :height="52" vertical :autoplay="4000" :duration="1000" :show-indicators="false">
							<van-swipe-item v-for="(val, key) in yuepaolist" :key="key">
								<div>
									<span>{{ val.name }}</span>
									<span>{{ val.tel }}</span>
								</div>
								<div>
									<span>{{ val.title }}</span>
								</div>
							</van-swipe-item>
						</van-swipe>
					</div>
				</van-tab>
				<van-tab :title="$t('home.index.271b6x')">
					<div class="card">{{$t('home.index.7521p4')}}</div>
					<div class="card">{{$t('home.index.yzxn3c')}}</div>
					<div class="card">{{$t('home.index.102566')}}</div>
					<div class="rig-box">
						<p class="rig-title">{{$t('home.index.ftsut5')}}</p>
						<p class="rig-content">{{$t('home.index.y75fd5')}}</p>
                        <p class="rig-content">{{$t('home.index.h83si8')}}</p>
                        <p class="rig-content">{{$t('home.index.bupuo6')}}</p>
                        <p class="rig-content">{{$t('home.index.015xt1')}}</p>
                        <p class="rig-content">{{$t('home.index.241j69')}}</p>
						<p class="rig-title">{{$t('home.index.r764wg')}}</p>
						<p class="rig-content">{{$t('home.index.5g8uu8')}}</p>
						<p class="rig-content">{{$t('home.index.p8dvv7')}}</p>
						<p class="rig-content">{{$t('home.index.dkx49a')}}</p>
						<p class="rig-content">{{$t('home.index.q8toq9')}}</p>
						<p class="rig-content">{{$t('home.index.4i6p6e')}}</p>
                        <p class="rig-content">{{$t('home.index.q5v662')}}</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			domain: this.serviceUrl,
			addlist: [
				{
					0: this.$t('home.index.bgvt5h'),
					1: this.$t('home.index.nf5643'),
					2: this.$t('home.index.e28uee'),
					3: this.$t('home.index.3545k6')
				},
				{
					0: this.$t('home.index.ap5971'),
					1: this.$t('home.index.1553l4'),
					2: this.$t('home.index.76f1ej'),
					3: this.$t('home.index.78e189')
				}
			],
			images: ['img/home/1.png', 'img/home/2.png', 'img/home/3.png', 'img/home/4.png'],
			xuanfeilist: [],
			peiduilist: [
				{
					name: 'Mr. Ding',
					tel: '(0060****8881)',
					title: this.$t('home.index.151482')
				},
				{
					name: 'Mrs. Zhu',
					tel: '(0065****6547)',
					title: this.$t('home.index.151482')
				},
				{
					name: 'Mr.L',
					tel: '(0061****5567)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mrs.Chen',
					tel: '(0001****2411)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mrs.Liu',
					tel: '(0085****0346)',
					title: this.$t('home.index.151482')
				},
				{
					name: 'Mr.Zhang',
					tel: '(0081****2218)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mr.Clark',
					tel: '(0064****5377)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mrs.Bein',
					tel: '(0060****9631)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mrs.Paul',
					tel: '(139****2044)',
					title: this.$t('home.index.151482')
				},
				{
					name: 'Mrs.Brown',
					tel: '(0044****2195)',
					title: this.$t('home.index.151482')
				},
				{
					name: 'Mrs.Po',
					tel: '(177****7564)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mr.Cha',
					tel: '(0088****1473)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mrs.Chu',
					tel: '(0066****6603)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mrs.Chia',
					tel: '(0086****8821)',
					title: this.$t('home.index.151482')
				},
				{
					name: 'Mrs.',
					tel: '(0085****8836)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mrs.Hwang',
					tel: '(131****8000)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mrs.Won',
					tel: '(182****1103)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mr.Koo',
					tel: '(0039****8163)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mrs.Keh',
					tel: '(0033****8221)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mrs.Kor',
					tel: '(0049****8221)',
					title: this.$t('home.index.151482')
				},
				{
					name: 'Mrs.Tou',
					tel: '(0086****7533)',
					title: this.$t('home.index.2gi1ut')
				},
				{
					name: 'Mrs.Lai',
					tel: '(0081****2345)',
					title: this.$t('home.index.2gi1ut')
				}
			],
			yuepaolist: [
				{
					name: 'Mrs.Leng',
					tel: '(0060****4356)',
					title: this.$t('home.index.5v240w')
				},
				{
					name: 'Mr.Au',
					tel: '(0065****8432)',
					title: this.$t('home.index.w4iy34')
				},
				{
					name: 'Mrs.Pee',
					tel: '(0060****8844)',
					title: this.$t('home.index.967989')
				},
				{
					name: 'Mrs.Tan',
					tel: '(154****1280)',
					title: this.$t('home.index.c7g4i9')
				},
				{
					name: 'Mrs.Chien',
					tel: '(0066****8020)',
					title: this.$t('home.index.5m3i5e')
				},
				{
					name: 'Mr.Wan',
					tel: '(0001****8345)',
					title: this.$t('home.index.217s82')
				},
				{
					name: 'Mrs.Hsing',
					tel: '(0044****8780)',
					title: this.$t('home.index.5607du')
				},
				{
					name: 'Mr.See',
					tel: '(0060****8970)',
					title: this.$t('home.index.957t61')
				},
				{
					name: 'Mrs.You',
					tel: '(0065****8670)',
					title: this.$t('home.index.t329l6')
				},
				{
					name: 'Mrs.Chong',
					tel: '(0081****8760)',
					title: this.$t('home.index.m5qq4k')
				},
				{
					name: 'Mrs.Li',
					tel: '(0086****8570)',
					title: this.$t('home.index.6tgwyb')
				},
				{
					name: 'Mrs.',
					tel: '(0060****8340)',
					title: this.$t('home.index.7nghkd')
				},
				{
					name: 'Mrs.',
					tel: '(0001****8880)',
					title: this.$t('home.index.yigf3d')
				},
				{
					name: 'Mrs.Yon',
					tel: '(0081****8843)',
					title: this.$t('home.index.5e6668')
				},
				{
					name: 'Mr.Goon',
					tel: '(0061****8880)',
					title: this.$t('home.index.k14bj4')
				},
				{
					name: 'Mrs.Xi',
					tel: '(0060****8880)',
					title: this.$t('home.index.knwc7w')
				},
				{
					name: 'Mrs.Ding',
					tel: '(0060****8880)',
					title: this.$t('home.index.11u846')
				},
				{
					name: 'Mrs.Hsiung',
					tel: '(0085****3430)',
					title: this.$t('home.index.73m14e')
				},
				{
					name: 'Mrs.Sang',
					tel: '(0085****2922)',
					title: this.$t('home.index.360tn3')
				},
				{
					name: 'Mr.Sze',
					tel: '(0060****3930)',
					title: this.$t('home.index.4du8f3')
				},
				{
					name: 'Mr.Mei',
					tel: '(0065****8570)',
					title: this.$t('home.index.6kv7rl')
				},
				{
					name: 'Mrs.Ching',
					tel: '(0044****8340)',
					title: this.$t('home.index.1myimn')
				},
				{
					name: 'Mrs.Mai',
					tel: '(0060****8880)',
					title: this.$t('home.index.53ng73')
				},
				{
					name: 'Mr.Kan',
					tel: '(0082****8843)',
					title: this.$t('home.index.25278v')
				},
				{
					name: 'Mrs.Smith',
					tel: '(0065****8880)',
					title: this.$t('home.index.27eju7')
				},
				{
					name: 'Mr.Miller ',
					tel: '(0082****8880)',
					title: this.$t('home.index.m62231')
				},
				{
					name: 'Mrs.Kan',
					tel: '(0033****8880)',
					title: this.$t('home.index.ync69o')
				},
				{
					name: 'Mr.honour',
					tel: '(0033****3430)',
					title: this.$t('home.index.r65qkm')
				},
				{
					name: 'Mr.Davis',
					tel: '(0044****2922)',
					title: this.$t('home.index.873xdt')
				},
				{
					name: 'Mr.Jack',
					tel: '(0060****3930)',
					title: this.$t('home.index.z24jqu')
				}
			]
		};
	},
	methods: {
		addgo(data) {
			// if (!localStorage.getItem('token')) {
			// 	this.$router.push({ path: '/Login' });
			// } else {
			// 	this.$router.push({
			// 		path: '/list?id=' + data.id + '&name=' + data.name
			// 	});
			// }
			this.$router.push({
				path: '/list?id=' + data.id + '&name=' + data.name
			});
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'indexlist'
			}).then(res => {
				this.xuanfeilist = res.data;
			});
		},
		// getPeiduilist() {
		// 	this.$http({
		// 		method: 'get',
		// 		url: 'indexlistpeidui'
		// 	}).then(res => {
		// 		this.peiduilist = res.data;
		// 	});
		// },
		// getYuepaolist() {
		// 	this.$http({
		// 		method: 'get',
		// 		url: 'indexlistyuepao'
		// 	}).then(res => {
		// 		this.yuepaolist = res.data;
		// 	});
		// },
		xfts(id) {
			this.$router.push({ path: '/Detail?id=' + id });
		}
	},
	created() {
		this.getAddress();
		// this.getPeiduilist();
		// this.getYuepaolist();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #16151b;
	// height: 100%;
}
.nav-bar {
	// background: linear-gradient(90deg, #7e5678, #e6c3a1);
	height: 100px;
	background-color: #16151b;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	font-size: 35px;
	color: #f3c7a2;
	font-weight: bold;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.my-swipe {
	border-radius: 10px;
	height: 310px;
}
.my-swipe .van-swipe-item {
	color: #fff;
	font-size: 20px;
	// line-height: 510px;
	text-align: center;
	background-color: #39a9ed;
}
.convention-hall {
	// display: flex;
	// flex-direction: column;
	// bottom: 20px;
	// background: #f2f2f5;
	// height: 100%;
}
.convention-item {
	width: 95%;
	margin: 0 auto;
	margin-bottom: 3.5625rem;
}
.banner_img {
	object-fit: cover;
	display: block;
	width: 100%;
	height: 100%;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
	color: #fff;
}
::v-deep .van-tabs__line {
	background-color: #f3c7a2;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.describe {
	width: 100%;
	min-height: 9, 875rem;
	border-radius: 0.625rem;
	border: 1px solid #766350;
	margin: 0.625rem 0;
	background: linear-gradient(120deg, #212121, #313131);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.5rem;
	position: relative;
	overflow: hidden;
}
.describe > ul {
	height: 5.1875rem;
	display: flex;
	align-items: flex-start;
}
.describe > ul > li:first-child {
	width: 7.75rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	border-right: 0.1875rem solid #e6b98a;
}
.describe > ul li {
	height: 100%;
}
.describe > div {
	height: 3.875rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #e6b98a;
	line-height: 1.5rem;
	font-size: 0.75rem;
}
.describe > ul > li:first-child > div:first-child {
	font-size: 1rem;
	font-weight: 700;
}
.describe > ul > li:first-child div {
	color: #f3c7a2;
	height: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.describe > ul > li:first-child > div:nth-child(2) {
	font-size: 0.8125rem;
}
.describe > ul > li:first-child div {
	color: #f3c7a2;
	height: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.describe > ul > li:nth-child(2) {
	padding: 0 0 0 0.625rem;
	width: calc(100% - 7.6rem);
	font-size: 0.625rem;
	line-height: 1.5625rem;
	color: #fff;
}
.describe > ul li {
	height: 100%;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
::v-deep .van-tabs__nav {
	background-color: #16151b;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
	color: #fff;
}
.address {
	width: 90%;
	margin: 0 auto;
}
.xuanfei {
	width: 100%;
	height: 9.75rem;
	border-radius: 0.625rem;
	border: 0.0625rem solid #766350;
	margin: 0.625rem 0 0.625rem 0;
	background: linear-gradient(120deg, #212121, #313131);
	display: flex;
	justify-content: space-between;
	padding: 0.625rem;
	position: relative;
	overflow: hidden;
}
.xuanfei .left-div {
	width: 12.9375rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.xuanfei .right-div {
	width: 7.75rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.xuanfei .sign-i {
	position: absolute;
	right: -40px;
	top: 10px;
	background: #ff6056;
	font-size: 15px;
	padding: 10px 45px;
	font-style: normal;
	transform: rotate(45deg);
	color: #fff;
}
.xuanfei .left-div > div:first-child {
	background: linear-gradient(90deg, #efcba7, #dcab76);
	color: #8d5825;
	height: 1.25rem;
	padding: 0 1.25rem;
	border-radius: 0.625rem;
	line-height: 1.25rem;
	text-align: center;
	font-size: 1rem;
}
.xuanfei .left-div > div:nth-child(2) {
	margin: 0.625rem 0;
	padding-left: 0.625rem;
}
.xuanfei .left-div > div:nth-child(2) ul li {
	height: 1.125rem;
	line-height: 1.125rem;
	text-align: left;
	font-size: 0.875rem;
	color: #fff;
}
.xuanfei .left-div > div:nth-child(3) ul {
	display: flex;
}
.xuanfei .left-div > div:nth-child(3) ul > li:first-child {
	margin-right: 0.625rem;
}
.xuanfei .left-div > div:nth-child(3) ul li {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.xuanfei .left-div > div:nth-child(3) ul li img {
	height: 0.8125rem;
	margin-right: 0.125rem;
}
.xuanfei .left-div > div:nth-child(3) ul li span {
	font-size: 0.8125rem;
	color: #696969;
}
.xuanfei .right-div img {
	width: 6.5rem;
	height: 6.5rem;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 50%;
}
.scroll-cmp {
	width: 100%;
	height: 12.9375rem;
	border-radius: 0.625rem;
	border: 0.0625rem solid #766350;
	margin: 0.625rem 0 6.25rem 0;
	background: linear-gradient(120deg, #212121, #313131);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0.625rem;
	position: relative;
	overflow: hidden;
}
.scroll-cmp .van-swipe-item {
	height: 1.125rem;
}
.scroll-cmp .van-swipe-item div:first-child span {
	color: #e6b98a;
	font-size: 0.9375rem;
}
.scroll-cmp .van-swipe-item div:nth-child(2) span {
	color: #fff;
	font-size: 0.7125rem;
}
</style>
