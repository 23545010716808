<template>
	<div class="container page">
		<van-nav-bar :title="$t('game.fangpian.6e92kt')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#e6b98a" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="scroll-div">
			<ul>
				<li><img src="/img/home/fangpian-1.0b1b930b.png" alt="" /></li>
				<li>
					<div>
						<p>{{$t('game.fangpian.lgy8fw')}}</p>
						<p>{{$t('game.fangpian.u23lm4')}}</p>
						<p>{{$t('game.fangpian.ukb73x')}}</p>
					</div>
					<img src="/img/home/fangpian-2.2b234561.png" alt="" />
				</li>
				<li>
					<img src="/img/home/fangpian-3.6f7acad2.png" alt="" />
					<div>
						<p class="warn-p">{{$t('game.fangpian.l0u1zs')}}</p>
						<p>{{$t('game.fangpian.60e346')}}</p>
						<p>{{$t('game.fangpian.3ko9qh')}}</p>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	methods: {
		back() {
			return window.history.back();
		}
	}
};
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';
::v-deep .van-nav-bar__title {
	color: #e6b98a;
}
.scroll-div {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	background-color: #fff;
}
.scroll-div ul {
	width: 95%;
	margin: 0 auto;
	height: 100%;
	color: #000;
}
.scroll-div ul li:first-child {
	height: 6.5rem;
}
.scroll-div ul li:first-child img {
	width: 90%;
	height: 6.5rem;
	-o-object-fit: contain;
	object-fit: contain;
	display: block;
	margin: 0 auto;
}
.scroll-div ul li:nth-child(2) {
	height: 15rem;
	word-break: break-all;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 0.625rem;
}
.scroll-div ul li:nth-child(2) div {
	width: 80%;
}
.scroll-div ul p {
	color: #000;
	font-size: 0.875rem;
	margin-bottom: 0.3125rem;
	font-weight: bold;
}
.scroll-div ul li:nth-child(2) img {
	width: 5.1875rem;
	height: 16rem;
	-o-object-fit: contain;
	object-fit: contain;
}
.scroll-div ul li:nth-child(3) {
	height: 14.25rem;
	word-break: break-all;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.scroll-div ul li:nth-child(3) img {
	width: 6.5rem;
	height: 16rem;
	float: right;
	-o-object-fit: contain;
	object-fit: contain;
}
.scroll-div ul li:nth-child(3) div {
	width: 80%;
}
.scroll-div ul p.warn-p {
	color: #e6b98a;
}
</style>
